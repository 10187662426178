<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.bulkPriceEdit') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="bundlePriceForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyingPrice') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="form.costPrice"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="form.costPrice = $event"
                                />
                                <span class="font-md ml-3" style="width: 50px !important;">{{ getCurrencyCode(stockOrderCurrencyId) }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
<!--                    <v-layout row>-->
<!--                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.importDuty') }}</v-flex>-->
<!--                        <v-flex xs7 lg7 md6 py-1>-->
<!--                            <div class="d-flex flex-row align-items-center">-->
<!--                                <v-text-field-->
<!--                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"-->
<!--                                    :value="form.importDuty"-->
<!--                                    autocomplete="off"-->
<!--                                    class="force-text-left"-->
<!--                                    dense-->
<!--                                    hide-details="auto"-->
<!--                                    solo-->
<!--                                    @change="form.importDuty = $event"-->
<!--                                />-->
<!--                                <span class="font-md ml-3" style="width: 50px !important;">{{ $t('message.na') }}</span>-->
<!--                            </div>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
<!--                    <v-layout row>-->
<!--                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.storageCost') }}</v-flex>-->
<!--                        <v-flex xs7 lg7 md6 py-1>-->
<!--                            <div class="d-flex flex-row align-items-center">-->
<!--                                <v-text-field-->
<!--                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"-->
<!--                                    :value="form.warehouseCost"-->
<!--                                    autocomplete="off"-->
<!--                                    class="force-text-left"-->
<!--                                    dense-->
<!--                                    hide-details="auto"-->
<!--                                    solo-->
<!--                                    @change="form.warehouseCost = $event"-->
<!--                                />-->
<!--                                <span class="font-md ml-3" style="width: 50px !important;">{{ $t('message.na') }}</span>-->
<!--                            </div>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
<!--                    <v-layout row>-->
<!--                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.freightCost') }}</v-flex>-->
<!--                        <v-flex xs7 lg7 md6 py-1>-->
<!--                            <div class="d-flex flex-row align-items-center">-->
<!--                                <v-text-field-->
<!--                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"-->
<!--                                    :value="form.forwardingCharge"-->
<!--                                    autocomplete="off"-->
<!--                                    class="force-text-left"-->
<!--                                    dense-->
<!--                                    hide-details="auto"-->
<!--                                    solo-->
<!--                                    @change="form.forwardingCharge = $event"-->
<!--                                />-->
<!--                                <span class="font-md ml-3" style="width: 50px !important;">{{ $t('message.na') }}</span>-->
<!--                            </div>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesPrice') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="form.sellingPrice"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="form.sellingPrice = $event"
                                />
                                <span class="font-md ml-3" style="width: 50px !important;">{{ getCurrencyCode(salesOrderCurrencyId) }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn :loading="loading.save" color="info" small @click="saveBundle()">{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

export default {
    name: "SalesOrderBundlePriceUpdate",
    props: ['selectedItems','salesOrderCurrencyId','dialog','dialog-closed','update-done','update-mode'],
    data(){
        return {
            edit_dialog: false,
            form: {
                costPrice: null,
                forwardingCharge: null,
                importDuty: null,
                sellingPrice: null,
                warehouseCost: null
            },
            loading: {
                save: false
            },
            oldValues: {},
            stockOrderCurrencyId: 1,
            validForm: false
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapMultiRowFieldsPrefixed('salesorderitem',{
            SalesOrderItems: 'SalesOrderItems',
        },'statePrefix'),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        // salesOrderCurrencyCode () {
        //     const currency = this.allCurrencies.find(c => c.Currency.id == this.currencyId)
        //     if(currency) return currency.Currency.code
        //     return ''
        // }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        getCurrencyCode (currencyId) {
            const currency = this.allCurrencies.find(c => c.Currency.id === currencyId)
            if(currency) return currency.Currency.code
            return 'USD'
        },
        saveBundle () {
            if(this.$refs.bundlePriceForm.validate()) {
                let values = {}
                if(this.form.costPrice != null){
                    values.costprice = this.form.costPrice
                }
                if(this.form.forwardingCharge != null){
                    values.forwardingcharge = this.form.forwardingCharge
                }
                if(this.form.importDuty != null){
                    values.importduty = this.form.importDuty
                }
                if(this.form.warehouseCost != null){
                    values.warehousecost = this.form.warehouseCost
                }
                if(this.form.sellingPrice != null){
                    values.sellingprice = this.form.sellingPrice
                }
                const payload = {
                    indexes: this.selectedItems,
                    prefix: this.statePrefix,
                    values: values
                }
                const updatePromise = new Promise((resolve, reject) => {
                    try {
                        this.$store.commit('salesorderitem/updateSalesOrderItemRows', payload)
                        resolve('success')
                    } catch (error) {
                        reject('error')
                    }
                })
                updatePromise
                    .then((status) => {
                        if(status == 'success'){
                            this.$toast.success(this.$t('message.successes.bundlesUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done', { reloadList : false })
                        } else {
                            this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        }
    },
    watch: {
        dialog(value) {
            this.form.costPrice = null
            this.form.forwardingCharge = null
            this.form.importDuty = null
            this.form.sellingPrice = null
            this.form.warehouseCost = null
            this.edit_dialog = value
            if(value === true){
                this.$nextTick(() => {
                    const stock = _.cloneDeep(this.SalesOrderItems[this.selectedItems[0]]?.['Stock'])
                    this.stockOrderCurrencyId = stock.StockOrder.currency_id
                })
            }
        }
    },
    created () {
        if(this.allCurrencies.length === 0) this.getAllCurrencies()
    }
}
</script>

<style scoped>

</style>